




































































































.AspectRatioPlaceholder {
  position: relative;
  background: rgba(0, 0, 0, 0.05);
}

.AspectRatioPlaceholder__embed {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: auto;
}
