/**
 * Horizontal text alignment
 */

.u-textDecorationNone {
  text-decoration: none !important;
}

.u-textCenter {
  text-align: center !important;
}

.u-textLeft {
  text-align: left !important;
}

.u-textRight {
  text-align: right !important;
}

.u-preSpace {
  white-space: pre;
}

.u-noWrap {
  white-space: nowrap !important;
}

.u-normalWrap {
  white-space: normal !important;
}

.u-noWrapTruncate {
  white-space: nowrap !important;
  overflow: hidden !important;
}

.u-noWrapWithEllipsis {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
}

.u-wordBreakNormal {
  word-break: normal !important;
}

.u-breakWord {
  word-break: break-word;
  word-wrap: break-word;
}

.u-hideText {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
}
