// custom
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
}

body {
  font-family: $font-family;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;

  color: $body-color;
  background-color: $body-background-color;

  text-rendering: optimizeLegibility;
  -moz-font-feature-settings: 'liga' on;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button {
  border: 0;
}

input[type='text'] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
