/**
 * @define utilities
 * Contain floats
 *
 * Make an element expand to contain floated children.
 * Uses pseudo-elements (micro clearfix).
 *
 * 1. The space content is one way to avoid an Opera bug when the
 *    `contenteditable` attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of the
 *    element.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

.u-cf::before,
.u-cf::after {
  content: ' '; /* 1 */
  display: table; /* 2 */
}

.u-cf::after {
  clear: both;
}

/**
  * Floats
  */

.u-fl {
  float: left !important;
}

.u-fr {
  float: right !important;
}

/**
  * New block formatting context
  *
  * This affords some useful properties to the element. It won't wrap under
  * floats. Will also contain any floated children.
  * N.B. This will clip overflow. Use the alternative method below if this is
  * problematic.
  */

.u-nbfc {
  overflow: hidden !important;
}

/**
  * New block formatting context (alternative)
  *
  * Alternative method when overflow must not be clipped.
  *
  * 1. Create a new block formatting context (NBFC).
  * 2. Avoid shrink-wrap behaviour of table-cell.
  *
  * N.B. This breaks down in some browsers when elements within this element
  * exceed its width.
  */

.u-nbfcAlt {
  display: table-cell !important; /* 1 */
  width: 10000px !important; /* 2 */
}

// align
.u-centerX {
  display: flex !important;
  align-items: center;
}

.u-centerY {
  display: flex !important;
  justify-content: center;
}

.u-centerXY {
  display: flex !important;
  align-items: center;
  justify-content: center;
}
