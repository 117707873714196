











































































$page-width: 375;
@import "~style/tool";

.item {
  line-height: 1;
  background: #fff;
  border-radius: 2px;
  overflow: hidden;
  padding-bottom: 8px;

  :global {
    .AspectRatioPlaceholder {
      background: transparent;
    }
  }
}

.itemImg {
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 100%;
}
.itemDetail {
  line-height: 1;
}
.itemName {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
}
.itemText1 {
  height: 17px;
  margin-top: 6px;
  font-size: 12px;
  color: #999999;
}

.price {
  display: flex;
  align-items: center;
  margin-top: 6px;
  margin-left: -2px;
}
.promptPrice {
  font-size: 16px;
  font-weight: 500;
  color: #ab2b2c;
}
.salePrice {
  // margin-top: rem(4);
  margin-left: 3px;
  font-size: 12px;
  color: #999999;
  text-decoration: line-through;
}

.tags {
  margin-top: 4px;
  height: 18px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}

.tag {
  flex: 0 0 auto;
  color: #ab2b2c;
  background-color: rgba(255, 255, 255, 1);
}

.tagText {
  font-size: 12px;
  transform-origin: center;
  transform: scale(0.91);
  padding: 3px 1px;
}

.tag + .tag {
  margin-left: 4px;
}
