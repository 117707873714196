@import "~style/tool.scss";

.Notify-enter {
  opacity: 0.01;
}

.Notify-enter.Notify-enter-active {
  opacity: 1;
  transition: opacity 225ms ease-out;
}

.Notify-leave {
  opacity: 1;
}

.Notify-leave.Notify-leave-active {
  opacity: 0.01;
  transition: opacity 195ms ease-in;
}

.Notify {
  word-break: break-all;
  overflow: hidden;
  position: fixed;
  top: 45%;
  left: 0;
  right: 0;
  width: 100%;
  transform: translateY(-50%);
  text-align: center;
  z-index: $zindex-tooltip;
}

.Notify__main {
  border-radius: px2em(6);
  display: inline-block;
  max-width: 80%;
  max-height: 122 * 2px;
  background-color: rgba(51, 51, 51, 0.7);
  color: white;

  @media screen and (min-width: $screen-md-min) {
    max-width: 450px;
  }
}

.Notify__text {
  padding: 4px 38px 4px 28px;
  padding: px2em(8) px2em(28) px2em(8) px2em(28);
  font-size: 14px;
}

.Notify__close {
  position: absolute;
  color: rgba(0, 0, 0, 0.43);
  right: 2px;
  top: -4px;
}

// .Notify.Notify--success .Notify__main {
//   border: 1px solid #cfefdf;
//   background-color: #ebf8f2;
//   color: rgba(0, 0, 0, 0.65);
// }

// .Notify.Notify--info .Notify__main {
//   border: 1px solid #d2eafb;
//   background-color: #ecf6fd;
//   color: rgba(0, 0, 0, 0.65);
// }

// .Notify.Notify--warning .Notify__main {
//   border: 1px solid #fff3cf;
//   background-color: #fffaeb;
//   color: rgba(0, 0, 0, 0.65);
// }

// .Notify.Notify--error .Notify__main {
//   border: 1px solid #fcdbd9;
//   background-color: #fef0ef;
//   color: rgba(0, 0, 0, 0.65);
// }
