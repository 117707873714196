.u-hide {
  display: none !important;
}

.u-invisible {
  visibility: hidden !important;
}

.u-inline {
  display: inline !important;
}

.u-inlineBlock {
  display: inline-block !important;
}

.u-block {
  display: block !important;
}

@media screen and (max-width: $screen-sm-max) {
  .u-sm-hide {
    display: none !important;
  }
}

@media screen and (max-width: $screen-md-max) {
  .u-md-hide {
    display: none !important;
  }
}

@media screen and (max-width: $screen-lg-max) {
  .u-lg-hide {
    display: none !important;
  }
}

.u-relative {
  position: relative !important;
}

.u-absolute {
  position: absolute !important;
}

.u-fixed {
  position: fixed !important;
}

.u-absolute0 {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
}

.u-clearLinkStyle {
  color: inherit;
  @include resetText();
}

.u-clearLinkStyle:active,
.u-clearLinkStyle:hover {
  color: inherit;
  @include resetText();
}
