@mixin truncateText($line: 1, $font-size: $font-size-base, $line-height: $line-height-base) {
  overflow: hidden;

  @if $line == 1 {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } @else {
    $single-line-height: $line-height;
    @if unitless($line-height) {
      $single-line-height: $line-height * $font-size;
    }
    max-height: ceil($line * $single-line-height);
    display: -webkit-box;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $line;
    white-space: normal;
  }
}

// resetText($font-family: $font-family, $line-height-base: $line-height-base)
//
// 长方形
//
// $font-family 	 - 字体.
// $line-height-base   - 行高.
//
// Styleguide Sass.mixins.shape.rectangle
@mixin resetText($font-family: $font-family, $line-height-base: $line-height-base) {
  font-family: $font-family;
  // We deliberately do NOT reset font-size.
  font-style: normal;
  font-weight: normal;
  letter-spacing: normal;
  line-break: auto;
  -webkit-line-break: auto;
  line-height: $line-height-base;
  text-align: left; // Fallback for where `start` is not supported
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
}
